import { getEmail } from 'shared/email';
import formData from 'form-data';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Mailgun from 'mailgun.js';

export const DOMAIN = 'esgintegrity.app';

const mailgun = new Mailgun(formData);

interface SendEmailProps {
  from: string;
  replyTo?: string;
  html: string;
  subject: string;
}

export const sendEmail = ({ from, replyTo, html, subject }: SendEmailProps) => {
  const mg = mailgun.client({
    key: process.env.MAILGUN_API_KEY || '',
    username: 'api',
  });

  return mg.messages.create(`mail.${DOMAIN}`, {
    from,
    'h:Reply-To': replyTo,
    html,
    subject,
    to: [getEmail()],
  });
};

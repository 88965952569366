import { ApolloError } from '@apollo/client';
import { logError } from '../logging';
import { useToasts } from 'react-toast-notifications';

export const useSuccessAndErrorOptions = ({
  errorMessage,
  successMessage,
}: {
  errorMessage?: string;
  successMessage?: string;
}): {
  onError: (error: ApolloError) => void;
  onCompleted: () => void;
} => {
  const { addToast } = useToasts();

  return {
    onError: (error) => {
      logError(error);
      addToast(errorMessage || 'Please try again', {
        appearance: 'error',
      });
    },
    onCompleted: () => {
      addToast(successMessage || 'Updated successfully', {
        appearance: 'success',
      });
    },
  };
};

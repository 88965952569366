import { Images, Routes } from 'client/enums';
import Link from 'next/link';
import React, { useState } from 'react';

import { ContactForm } from '../generic/ContactForm';
import { Modal } from '../generic/Modal';

export const Footer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div className="w-full bg-blue-primary p-[5%] md:px-[5%] flex flex-col md:flex-row justify-between text-white z-50">
      <div className="flex flex-col w-full mb-8 md:mb-0 md:align-start">
        <img
          alt="Logo"
          className="self-center md:self-start w-[400px]"
          src={Images.WhiteLogo}
        />
        <div className="mt-8 hidden md:block">
          <a
            className="flex mb-2"
            href="https://wearechameleon.com"
            target="blank"
          >
            <p className="mr-1">Powered by</p>
            <img alt="chameleon" className="h-5" src={Images.Chameleon} />
            <p className="ml-1">chameleon</p>
          </a>
          <p>Copyright © 2021 ESG Integrity, LLC. All Rights Reserved.</p>
        </div>
      </div>
      <div className="grid grid-flow-row grid-cols-1 md:grid-cols-3 gap-y-8 gap-x-32">
        <div className="rows-span-4 space-y-2 flex flex-col items-center md:items-start">
          <p className="text-white font-bold">Navigation</p>
          <Link href={Routes.HOME}>
            <p className="link">Home</p>
          </Link>
          <Link href={Routes.REPORTS}>
            <p className="link">Reports</p>
          </Link>
          <Link href={Routes.COMPANY_PROFILE}>
            <p className="link">Company Profile</p>
          </Link>
        </div>
        <div className="rows-span-4 space-y-2 flex flex-col items-center md:items-start">
          <p className="text-white font-bold">Support</p>
          <button
            className="cursor-pointer w-20 md:text-left text-center"
            onClick={() => setModalIsOpen(true)}
          >
            Contact Us
          </button>
          <Modal onClose={() => setModalIsOpen(false)} show={modalIsOpen}>
            <ContactForm />
          </Modal>
          <Link href={Routes.INDEX}>
            <p className="link">FAQ</p>
          </Link>
        </div>
      </div>
      <div className="mt-8 md:hidden flex flex-col items-center text-center">
        <a
          className="flex mb-2"
          href="https://wearechameleon.com"
          target="blank"
        >
          <p className="mr-1">Powered by</p>
          <img alt="chameleon" className="h-5" src={Images.Chameleon} />
          <p className="ml-1">chameleon</p>
        </a>
        <p>Copyright © 2021 ESG Integrity, LLC. All Rights Reserved.</p>
      </div>
    </div>
  );
};

import { Role } from '@prisma/client';
import { useUser } from 'client/contexts/user';
import { Images, Routes } from 'client/enums';
import { useAuthUser } from 'next-firebase-auth';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';

import { Dropdown } from '../generic/Dropdown';

export const NavBar: FC = () => {
  const authUser = useAuthUser();
  const user = useUser();
  const router = useRouter();
  const [activeRoute, setActiveRoute] = useState('');

  const onSignOut = () => {
    authUser.signOut();
    router.push(Routes.LOGIN);
  };

  useEffect(() => {
    setActiveRoute(router.asPath);
  }, [router.asPath]);

  return (
    <div className="flex flex-row items-center justify-between sticky top-0 w-full bg-white border-b border-gray-300 max-h-24 h-24 z-50 px-4 md:px-8 lg:px-12">
      <Link href={Routes.HOME}>
        <a className="h-16">
          <img alt="Logo" className="w-full h-full" src={Images.FCLogo} />
        </a>
      </Link>
      {authUser?.id ? (
        <div className="flex flex-row">
          <Link href="/reports">
            <div
              className={
                activeRoute === Routes.REPORTS ||
                activeRoute.includes(Routes.REPORTS)
                  ? 'active-menu-item-wrapper hidden md:flex'
                  : 'menu-item-wrapper'
              }
            >
              <a
                className={`navigation-text ${
                  activeRoute === Routes.REPORTS ||
                  activeRoute.includes(Routes.REPORTS)
                    ? 'text-green font-semibold'
                    : 'text-gray-text'
                }`}
              >
                {activeRoute.includes(Routes.REPORTS)
                  ? 'My Reports'
                  : 'Back to Reports'}
              </a>
            </div>
          </Link>
          <div className="flex flex-col justify-center relative ml-2">
            <Dropdown
              DropdownIconProps={{
                color: 'text-green-primary',
                width: 'w-6',
              }}
              className="bg-gray-25 rounded-lg h-3/5 self-center px-4 py-2"
              options={[
                {
                  className: 'mb-2 text-sm truncate',
                  text: user?.email || '',
                },
                {
                  className: 'md:hidden flex',
                  text: 'Reports',
                  href: Routes.REPORTS,
                },
                {
                  text: 'Company Profile',
                  href: Routes.COMPANY_PROFILE,
                },
                user?.role === Role.ADMIN && {
                  text: 'Permissions',
                  href: Routes.ADMIN,
                },
                {
                  text: 'Sign Out',
                  onClick: onSignOut,
                },
              ]}
              size="large"
              theme="gray"
            >
              <div className="navigation-text mr-8 md:flex hidden">
                Account Info
              </div>
            </Dropdown>
          </div>
        </div>
      ) : (
        <>
          {!activeRoute.includes(Routes.LOGIN) && (
            <div className="flex items-center justify-end w-48">
              <Link href={Routes.LOGIN}>
                <button className="sm:w-1/2 md:w-full button--primary">
                  Login
                </button>
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';

export const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  const postToMailGun = async ({
    name,
    email,
    company,
    phone,
    information,
    _honeypot,
  }) => {
    if (!_honeypot) {
      setLoading(true);

      const response = await fetch('/api/mailgun', {
        body: JSON.stringify({
          email,
          name,
          company,
          phone,
          information,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });

      if (response.status !== 200) {
        addToast(
          <div>
            <p>
              {
                'Unable to send contact information. Please try again or contact us directly at '
              }
            </p>
            <a className="text-blue-500" href="mailto:jhove@fuelsinstitute.org">
              jhove@fuelsinstitute.org
            </a>
          </div>,
          { appearance: 'error' }
        );
      } else {
        reset();
        addToast('Email sent successfully!', { appearance: 'success' });
      }

      setLoading(false);
    }
  };

  const onSubmit = handleSubmit(postToMailGun);

  return (
    <form
      className="w-full max-w-2xl space-y-6 py-2 lg:py-6 px-4 md:px-8 rounded-md text-center overflow-y-scroll bg-white"
      onSubmit={onSubmit}
    >
      <h3 className="header2">Contact Us</h3>
      <input
        className={`w-full input ${errors['name'] ? 'input-error' : ''}`}
        id="name"
        placeholder="Name*"
        {...register('name', { required: true })}
      />
      <input
        className={`w-full input ${errors['email'] ? 'input-error' : ''}`}
        id="email"
        placeholder="Email*"
        {...register('email', { required: true })}
      />
      <input
        className={`w-full input ${errors['phone'] ? 'input-error' : ''}`}
        id="phone"
        placeholder="Phone"
        {...register('phone', { required: false })}
      />
      <input
        className={`w-full input ${errors['company'] ? 'input-error' : ''}`}
        id="company"
        placeholder="Company*"
        {...register('company', { required: true })}
      />
      <textarea
        className={`w-full textarea ${
          errors['information'] ? 'input-error' : ''
        }`}
        placeholder="Write your message here"
        rows={12}
        {...register('information', { required: true })}
      />
      <input className="hidden" {...register('_honeypot')} />
      <div className="flex w-full justify-center">
        <button
          className="button--primary bg-blue-primary mt-2 button--fullWidth"
          disabled={loading}
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

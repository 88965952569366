/* eslint-disable no-restricted-globals */
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { getAbsoluteUrl } from 'shared/env';

let apolloClient;
let prevToken: string | null = null;

export const getApolloClient = (token: string | null) => {
  if (!apolloClient || prevToken !== token) {
    const authLink = new ApolloLink((operation, forward) => {
      const authHeader = token ? { Authorization: `Bearer ${token}` } : {};

      operation.setContext(({ headers }) => ({
        headers: {
          ...authHeader,
          ...headers,
        },
      }));

      return forward(operation);
    });

    const httpLink = new HttpLink({
      credentials: 'include',
      uri: getAbsoluteUrl('/api/graphql'),
    });

    apolloClient = new ApolloClient({
      link: from([authLink, httpLink]),
      cache: new InMemoryCache(),
    });
  }

  if (prevToken !== token) {
    prevToken = token;
  }

  return apolloClient;
};

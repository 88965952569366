import { User } from 'graphql-codegen';
import React, { useContext } from 'react';

const UserContext = React.createContext<{
  user: User | null;
}>({
  user: null,
});

interface UserProviderProps {
  children: any;
  user: User | null;
}

export const UserProvider = ({ children, user }: UserProviderProps) => {
  const value = {
    user,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);

export const useUser = () => {
  const userContext = useUserContext();

  return userContext.user;
};

export const useUserId = () => {
  const user = useUser();

  return user?.id;
};

import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { classNames } from 'client/utils';
import Link from 'next/link';
import { DropdownIcon, IDropdownIconProps } from './DropdownIcon';

interface IBaseOption {
  text: string;
  className?: string;
  href?: string;
}
interface IOptionClick extends IBaseOption {
  onClick?: () => void;
}

interface IOptionHref extends IBaseOption {
  href: string;
}

type Option = IOptionHref | IOptionClick | null | false;

interface IDropdownProps {
  children: JSX.Element;
  className?: string;
  options: Option[];
  DropdownIconProps?: Omit<IDropdownIconProps, 'isOpen'>;
  theme?: 'blue' | 'gray';
  size?: 'small' | 'large';
}

const isLink = (option: Option): option is IOptionHref =>
  Boolean(option && option.href);

export const Dropdown = ({
  children,
  className,
  DropdownIconProps,
  options,
  theme = 'blue',
  size = 'small',
}: IDropdownProps) => {
  const menuItemsClass = classNames(
    size === 'small' ? 'rounded' : 'rounded-card',
    'origin-top-right absolute right-0 mt-2 w-64 shadow-primary bg-white focus:outline-none z-10'
  );
  const menuItemWrapperClasses = classNames(size === 'small' ? 'py-4' : 'p-2');
  const menuItemClasses = classNames(
    size === 'large' ? 'rounded' : 'text-sm font-extrabold',
    'block px-4 py-2'
  );
  const hoverClasses = classNames(
    theme === 'blue'
      ? 'hover:bg-blue-primary hover:text-white'
      : 'hover:bg-gray-25',
    'cursor-pointer'
  );

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button as={Fragment}>
              {DropdownIconProps ? (
                <div className={`flex flex-row items-center ${className}`}>
                  {children}
                  <DropdownIcon isOpen={open} {...DropdownIconProps} />
                </div>
              ) : (
                children
              )}
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={open}
          >
            <Menu.Items className={menuItemsClass} static>
              <div className={menuItemWrapperClasses}>
                {options.map((option) => {
                  if (!option) {
                    return null;
                  }

                  if (!option.href && !option['onClick']) {
                    return (
                      <p
                        className={classNames(
                          option.className,
                          menuItemClasses,
                          'cursor-default'
                        )}
                        key={option.text}
                      >
                        {option.text}
                      </p>
                    );
                  }

                  return (
                    <Menu.Item key={option.text}>
                      {isLink(option) ? (
                        <Link href={option.href}>
                          <a
                            className={classNames(
                              option.className,
                              menuItemClasses,
                              hoverClasses
                            )}
                          >
                            {option.text}
                          </a>
                        </Link>
                      ) : (
                        <a
                          className={classNames(
                            option.className,
                            menuItemClasses,
                            hoverClasses
                          )}
                          onClick={option.onClick}
                        >
                          {option.text}
                        </a>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

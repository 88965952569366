import { Images } from 'client/enums';

export const DesktopOnly = () => {
  return (
    <div>
      <img alt="desktop-only" src={Images.DesktopOnly} width="80%" />
      <div className="p-4 bg-gray-25 mt-12 rounded-xl">
        <p className="font-extrabold uppercase">
          Please use desktop for this page
        </p>
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import { DesktopOnly } from './DesktopOnly';

import { NextSeo } from 'next-seo';
import { NavBar } from '../navigation/NavBar';
import { Footer } from '../navigation/Footer';
import { MetaData } from 'client/enums/meta';

interface IPageWrapperProps {
  children?: React.ReactNode;
  className?: string;
  LeftSideMenu?: any;
  requireDesktop?: boolean;
  hideNav?: boolean;
  hideFooter?: boolean;
  description?: string;
  title?: string;
}

export const PageWrapper: FC<IPageWrapperProps> = ({
  children,
  className,
  LeftSideMenu,
  requireDesktop,
  hideNav,
  hideFooter,
  description,
  title,
}) => {
  const visibility = requireDesktop ? 'hidden lg:block' : '';
  const hiddenVisibility = requireDesktop ? 'block lg:hidden' : 'hidden';

  return (
    <div className="relative">
      {!hideNav && <NavBar />}
      <div className={`${visibility} z-0`}>
        {LeftSideMenu ? LeftSideMenu : null}
        <div
          className={`flex flex-col w-full pt-8 min-h-screen ${
            LeftSideMenu ? 'pl-72' : ''
          }`}
        >
          <div className={`flex flex-col w-full ${className}`}>{children}</div>
        </div>
      </div>
      <NextSeo
        description={description || MetaData.DESCRIPTION}
        title={title || MetaData.TITLE}
      />
      <div
        className={`${hiddenVisibility} w-full h-full pt-12 flex flex-col justify-center items-center`}
      >
        <DesktopOnly />
      </div>
      {!hideNav && !hideFooter && <Footer />}
    </div>
  );
};

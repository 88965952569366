import { CombinedSubSections } from './../constants/combined-section-data';
import moment from 'moment';
import { SubSections } from 'client/constants/section-data';
import { ReportInputProp } from 'client/components/esg-metrics/generic/ReportSection';
import { FleetOwnerSubSections } from 'client/constants/fleet-owner-section-data';
import { CompanyType, Report, ReportType } from 'graphql-codegen';
import { LimitedSubSectionsEnabled } from 'client/constants/limited-section-data';

export const mapReportTypeToLabel: { [key in ReportType]: string } = {
  COMBINED: 'Combined',
  FLEET_OWNER: 'Fleet Operator',
  FUEL_RETAILER: 'Fuel Marketer',
  LIMITED: 'Carbon Avoidance Tracker',
};

export const mapCompanyTypeToLabel: { [key in CompanyType]: string } = {
  RETAILER: 'Fuel Marketer',
  TERMINAL_HOLDER: 'Fuel Marketer', // legacy value - now treated as "RETAILER"
  WHOLESALER: 'Fuel Marketer', // legacy value - now treated as "RETAILER"
  FLEET_OWNER: 'Fleet Operator',
  COMBINED: 'Fuel Marketer/Fleet Operator',
};

export const getSectionId = (
  section:
    | SubSections
    | FleetOwnerSubSections
    | CombinedSubSections
    | LimitedSubSectionsEnabled
) => {
  return section
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const getIdDisplay = (id: string) =>
  `${id.slice(0, 6)}...${id.slice(0, 4)}`;

export const getReportTitle = (report: { year: number }) =>
  `${report.year} Report`;

export const getReportSubtitle = (report: Report) => {
  return `${report.year} ${
    report.reportType ? mapReportTypeToLabel[report.reportType] : ''
  } Report`;
};

export const getReportTypeText = (reportType: ReportType) => {
  if (!reportType) {
    return 'FUEL MARKETER';
  } else {
    return mapReportTypeToLabel[reportType].toUpperCase();
  }
};

export const formatDateForDisplay = (date: string) =>
  moment(date).format('MMM. Do, YYYY h:mm A');

export const getSavedAtText = (date: string) => {
  const momentDate = moment(date);
  const isToday = moment().diff(momentDate, 'days') === 0;

  if (isToday) {
    return `Saved at ${momentDate.format('h:mm A')}`;
  }

  return `Last saved ${momentDate.format('MMM Do, h:mm A')}`;
};

export const getDbKeysFromInputs = (inputs: ReportInputProp[]): string[] => {
  const keys = inputs.map((item) => {
    if (Array.isArray(item)) {
      return getDbKeysFromInputs(item);
    }

    return item.dbKey;
  });

  return keys.flat();
};

import {
  fuelGHGEmissions,
  fuelGHGEmissionsLifecycle,
  mapDbKeyToFuelType,
} from './../constants/GHGEmissions';
import { Report } from 'graphql-codegen';

export const getProperYearFromEmissionsConstants = (
  year: number | undefined
): '2021' | '2022' => {
  if (year) {
    if (year <= 2021) {
      return '2021';
    } else {
      return '2022';
    }
    // add case for 2023 once values available
  }

  return '2022';
};

export const getGasolineOrEthanolFuelCarbonIntensity = (
  dbKey: keyof Report,
  report: Partial<Report>,
  treatAllAsGasoline = false,
  useLifecycle = false
) => {
  const coefficientYear = getProperYearFromEmissionsConstants(report.year);
  const emissionsMultiplier = useLifecycle
    ? fuelGHGEmissionsLifecycle
    : fuelGHGEmissions;
  const fuelType = treatAllAsGasoline ? 'gasoline' : mapDbKeyToFuelType[dbKey];
  const carbonIntensity = report[dbKey]
    ? report[dbKey] * emissionsMultiplier[coefficientYear][fuelType]
    : 0;

  return carbonIntensity;
};

export const getDieselOrBiodieselFuelCarbonIntensity = (
  dbKey: keyof Report,
  report: Partial<Report>,
  treatAllAsDiesel = false,
  useLifecycle = false
) => {
  const coefficientYear = getProperYearFromEmissionsConstants(report.year);
  const emissionsMultiplier = useLifecycle
    ? fuelGHGEmissionsLifecycle
    : fuelGHGEmissions;
  const fuelType = treatAllAsDiesel ? 'diesel' : mapDbKeyToFuelType[dbKey];
  const carbonIntensity = report[dbKey]
    ? report[dbKey] * emissionsMultiplier[coefficientYear][fuelType]
    : 0;

  return carbonIntensity;
};

export const getRNGFuelCarbonIntensity = (
  dbKey: keyof Report,
  report: Partial<Report>
) => {
  const coefficientYear = getProperYearFromEmissionsConstants(report.year);
  const fuelType = mapDbKeyToFuelType[dbKey];
  const carbonIntensity = report[dbKey]
    ? report[dbKey] * fuelGHGEmissions[coefficientYear][fuelType]
    : 0;

  return carbonIntensity;
};

export const getkWhReduction = (
  valueName: keyof Report,
  report: Partial<Report>,
  carbonIntensity: number
) => {
  return report[valueName] ? report[valueName] * carbonIntensity : 0;
};

export const getEVChargingReduction = (
  report: Partial<Report>,
  carbonIntensity: number
) => {
  const totalEVkWhDispensed = report.totalEVkWhDispensed || 0;
  const totalEVkWhCapacity = report.totalEVkWhCapacity || 0;

  return (
    totalEVkWhDispensed * carbonIntensity + totalEVkWhCapacity * carbonIntensity
  );
};

import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';

import { Env, getEnv, shouldConsoleLog } from '../shared/env';

export const setLoggingUser = (user: {
  id: string;
  email: string;
  name: string;
}): void => {
  Sentry.setUser(user);
};

export const logError = (error: ApolloError) => {
  try {
    if (shouldConsoleLog()) {
      console.error(error);
    }

    if ([Env.PROD, Env.DEV].includes(getEnv())) {
      Sentry.captureException(error, {
        extra: {
          page: window.location.pathname,
        },
      });
    }
  } catch (e) {
    if (shouldConsoleLog()) {
      console.error(`Error logging to Sentry -- `, e);
    }
  }
};

import { useEffect, useRef, useState } from 'react';
import { X } from 'react-feather';
import ReactDOM from 'react-dom';

export const Modal = ({ show, onClose, children }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleCloseClick = (e) => {
    e.preventDefault();
    onClose();
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        show &&
        componentRef.current &&
        !componentRef.current.contains(e.target)
      ) {
        e.preventDefault();
        onClose();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [show, onClose]);

  const modalContent = show ? (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
      <div
        className="bg-white h-auto rounded-xl py-2 px-4 relative"
        ref={componentRef}
      >
        <div className="absolute right-2 top-0">
          <div className="p-2 cursor-pointer" onClick={handleCloseClick}>
            <X className="text-primary" size={30} />
          </div>
        </div>
        <div className="py-3">{children}</div>
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById('modal-root')
    );
  } else {
    return null;
  }
};

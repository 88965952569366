import { ApolloProvider } from '@apollo/client';
import { PrismaClient, Role, Status, User } from '@prisma/client';
import { getApolloClient } from 'client/apolloClient';
import { UserProvider } from 'client/contexts/user';
import { isRoute, Routes } from 'client/enums';
import { setLoggingUser } from 'client/logging';
import { SSRPropsContext, withAuthUser } from 'next-firebase-auth';
import { ParsedUrlQuery } from 'node:querystring';
import { getOrCreateUser } from 'server/utils';
import React, { useEffect } from 'react';

export const withUser = (WrappedComponent) => {
  const FuncComponent = ({ children, token, user, ...props }) => {
    useEffect(() => {
      if (user) {
        setLoggingUser(user);
      }
    }, [user]);

    return (
      <ApolloProvider client={getApolloClient(token)}>
        <UserProvider user={user}>
          <WrappedComponent {...props}>{children}</WrappedComponent>
        </UserProvider>
      </ApolloProvider>
    );
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return withAuthUser()(FuncComponent);
};

const userIsApproved = (user: User) => user.status === Status.APPROVED;

const userIsAdmin = (user: User) => user.role === Role.ADMIN;

/**  
  Importing prisma from prisma/client within this file was causing issues, so have to pass it as a prop.
*/
export const getServerSidePropsUserAndToken = async (
  context: SSRPropsContext<ParsedUrlQuery>,
  prisma: PrismaClient,
  options?: {
    requireAdmin?: boolean;
    redirectToWhenAuthed?: Routes;
  }
) => {
  const { AuthUser } = context;

  if (AuthUser.id) {
    if (options?.redirectToWhenAuthed) {
      return {
        redirect: {
          destination: options?.redirectToWhenAuthed,
          permanent: false,
        },
      };
    }

    const token = await AuthUser.getIdToken(true);
    const user = await getOrCreateUser(AuthUser, prisma);

    if (!user) {
      return {
        redirect: {
          destination: Routes.LOGIN,
          permanent: false,
        },
      };
    }

    const companyMembers = await prisma.companyMember.findMany({
      where: {
        userId: user.id,
      },
    });

    // handle if they do not have a company created yet
    if (
      !isRoute(context.req.url, Routes.CREATE_COMPANY) &&
      (!companyMembers || !companyMembers.length)
    ) {
      return {
        redirect: {
          destination: Routes.CREATE_COMPANY,
          permanent: false,
        },
      };
    }

    // handle not approved
    if (
      !userIsApproved(user) &&
      !isRoute(context.req.url, Routes.PENDING_APPROVAL) &&
      !isRoute(context.req.url, Routes.CREATE_COMPANY)
    ) {
      return {
        redirect: {
          destination: Routes.PENDING_APPROVAL,
          permanent: false,
        },
      };
    }

    // handle if they already have a company and try to navigate to create company
    if (
      isRoute(context.req.url, Routes.CREATE_COMPANY) &&
      companyMembers.length
    ) {
      return {
        redirect: {
          destination: Routes.INDEX,
          permanent: false,
        },
      };
    }

    // handle rerouting pages that require admin privileges
    if (options?.requireAdmin && !userIsAdmin(user)) {
      return {
        redirect: {
          destination: Routes.ERROR_404,
          permanent: false,
        },
      };
    }

    // send users to the home page if they get approved
    if (
      isRoute(context.req.url, Routes.PENDING_APPROVAL) &&
      userIsApproved(user)
    ) {
      return {
        redirect: {
          destination: Routes.INDEX,
          permanent: false,
        },
      };
    }

    return {
      props: {
        token,
        user: {
          ...user,
          createdAt: user.createdAt.toISOString(),
          updatedAt: user.updatedAt.toISOString(),
        },
      },
    };
  }

  return {
    props: {
      token: null,
      user: null,
    },
  };
};

import { PrismaClient, Provider, Role, Status } from '@prisma/client';
import Cookies from 'cookies';
import { NextApiRequest, NextApiResponse } from 'next';
import { isLocalhost } from 'shared/env';
import { sendEmail, DOMAIN } from './mailgun';

const REFRESH_TOKEN_KEY = 'refreshToken';

export const setRefreshToken = (
  req: NextApiRequest,
  res: NextApiResponse,
  refreshToken: string | null
): void => {
  const cookies = new Cookies(req, res);

  const expirationDate = new Date();

  expirationDate.setFullYear(expirationDate.getFullYear() + 1);

  cookies.set(REFRESH_TOKEN_KEY, refreshToken, { expires: expirationDate });
};

export const getRefreshToken = (req: NextApiRequest, res: NextApiResponse) => {
  const cookies = new Cookies(req, res);

  return cookies.get(REFRESH_TOKEN_KEY);
};

export const getOrCreateUser = async (
  authUser: {
    id: string | null;
    email: string | null;
    displayName: string | null;
  },
  prisma: PrismaClient
) => {
  if (!authUser.email || !authUser.id) {
    return null;
  }

  const existingUser = await prisma.user.findFirst({
    select: {
      id: true,
      email: true,
      name: true,
      provider: true,
      status: true,
      role: true,
      createdAt: true,
      updatedAt: true,
    },
    where: {
      email: authUser.email,
    },
  });

  if (existingUser) {
    return existingUser;
  }

  const createdUser = await prisma.user.create({
    data: {
      id: authUser.id,
      email: authUser.email || '',
      name: authUser.displayName || '',
      provider: Provider.EMAIL,
      role: Role.USER,
      status: Status.PENDING,
    },
  });

  if (!isLocalhost()) {
    await sendEmail({
      from: `ESG Integrity <help@${DOMAIN}>`,
      html: `<h1>New Account Notification</h1><p><b>Name: </b>${authUser.displayName}</p><p><b>Email: </b>${authUser.email}</p>`,
      subject: `ESG Integrity Account Created`,
    });
  }

  return createdUser;
};

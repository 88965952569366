import React, { FC } from 'react';
import { useSpring, animated } from 'react-spring';

export interface IDropdownIconProps {
  color?: string;
  isOpen: boolean;
  width?: string;
  defaultRotation?: string;
}

export const DropdownIcon: FC<IDropdownIconProps> = ({
  isOpen,
  color,
  width,
  defaultRotation = 'rotateZ(0deg)',
}) => {
  const style = useSpring({
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    transform: isOpen ? 'rotateZ(180deg)' : defaultRotation,
    transition: 'transform 100ms',
  });

  return (
    <animated.div className="cursor-pointer" style={style}>
      <svg
        className={`${width ? width : 'w-8'} ${color}`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 9l-7 7-7-7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </svg>
    </animated.div>
  );
};

/**
 * Create an array of numbers from start to end, inclusive
 */
export const arrayRange = (start: number, end: number) => {
  const arr: number[] = [];

  for (let i = start; i <= end; i++) {
    arr.push(i);
  }

  return arr;
};
